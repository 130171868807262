// import '@/scss/main.scss';

import * as bootstrap from 'bootstrap';
import $ from 'jquery';

//used for jobs slider
import 'slick-carousel';

//image/video lightboxes
import GLightbox from 'glightbox';

//datepicker
import datepicker from 'bootstrap-datepicker';

//map library 
import 'leaflet';

//text animations 
import anime from 'animejs/lib/anime.es.js';

//vimeo player
import Player from '@vimeo/player';

import Glide from '@glidejs/glide';

import Lenis from 'lenis'


// import LocomotiveScroll from 'locomotive-scroll';

// const scroll = new LocomotiveScroll({
//     el: document.querySelector('[data-scroll-container]'),
//     smooth: true
// });

// import Scrollbar from 'smooth-scrollbar';

document.addEventListener('DOMContentLoaded',function(){
//     console.log(document.querySelector('body'));
    
//     Scrollbar.init(document.querySelector('body'), {
//         damping: 0.01,
//         thumbMinSize: 20
//     });

    // const lenis = new Lenis({
    //     duration: 1.5,
    //     easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    // })
    //
    // function raf(time) {
    //     lenis.raf(time)
    //     requestAnimationFrame(raf);
    // }
    //
    // requestAnimationFrame(raf);
})

window.jQuery = $;
window.$ = $;

window.Glide = Glide;

//text letters animatons 
function wrapCharacters(text) {
    return text.replace(/(.)/g, "<span class='letter'>$&</span>");
}

function animateText(element) {
    element.innerHTML = wrapCharacters(element.textContent);

    anime.timeline({loop: false})
        .add({
            targets: `${element.tagName.toLowerCase()} .letter`,
            opacity: [0,1],
            translateY: [0, 0],
            translateZ: 0,
            duration: 750,
            easing: "easeOutExpo",
            delay: (el, i) => 50 * i
        });
}

// page scroll indicator calculator
function updateScrollProgress() {
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    var scrollPercentage = (scrollTop / scrollHeight) * 100;
    
    document.getElementById('scroll-progress').style.width = scrollPercentage + '%';
}

window.addEventListener('scroll', updateScrollProgress);
updateScrollProgress();

// horizontal scroll indicator

function initializeScrollerIndicator(scrollerSelector, indicatorId, minValue = 0) {
    const scroller = document.querySelector(scrollerSelector);
    const progress = document.getElementById(indicatorId);

    if (!scroller || !progress) {
        console.log('Scroller or progress indicator not found.');
        return;
    }
    scroller.addEventListener('scroll', () => {
        console.log('test');
        updateProgress();
    });

    const updateProgress = () => {
        const scrollLeft = scroller.scrollLeft;
        const scrollWidth = scroller.scrollWidth - scroller.clientWidth;
        const scrollPercentage = (scrollLeft / scrollWidth) * 100;

        if(minValue && minValue > scrollPercentage) 
            progress.style.width = `${minValue}%`;
        else 
            progress.style.width = `${scrollPercentage}%`;
    };

    updateProgress();
}

window.initializeScrollerIndicator = initializeScrollerIndicator;

function initGlideProgressBar(glideInstance, sliderSelector, progressBarSelector) {

    var progressBar = document.querySelector(progressBarSelector);

    if (!progressBar) return;
    function updateProgressBar() {
        var totalItems = document.querySelectorAll(sliderSelector + ' .numbers-list li').length;
        var progressPercent = ((glideInstance.index + 1) / totalItems) * 100;
        progressBar.style.width = progressPercent + '%';
    }
    glideInstance.on(['mount.after', 'move.after'], updateProgressBar);
}

window.initGlideProgressBar = initGlideProgressBar;

// initializeScrollerIndicator('.numbers-scroller', 'numbers-progress', 30);
// initializeInViewObserver('.numbers-scroller', '.numbers-list .numbers-item', 0.5);

document.addEventListener('DOMContentLoaded', function() {
    
    window.addEventListener('orientationchange', handleOrientationChange);
        
    function handleOrientationChange() {
        resizeCarouselImages(); 
    }

    resizeCarouselImages();

    setTimeout(() => {
        
        initializeInViewObserver(null, '.header', 0, false);
        initializeInViewObserver(null, '.animate', .2, false);
        initializeInViewObserver(null, '.section', 0.2, false);
        // initializeInViewObserver(null, '.fadeInUp', 0.9, false);
        
        // initializeInViewObserver(null, '.section-heading', 0.2, false, function(target){
        //     animateText(target);
        // });
    }, 500);
});

function initializeInViewObserver(containerSelector, itemSelector, threshold = 0.5, fadeOut = true, callback = false) {
    const container = containerSelector ? document.querySelector(containerSelector) : null;
    const items = container ? container.querySelectorAll(itemSelector) : document.querySelectorAll(itemSelector);

    if (items.length === 0) {
        console.log(itemSelector);
        console.log('Items not found.');
        return;
    }

    const observerOptions = {
        root: container,
        rootMargin: '0px',
        threshold: threshold 
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('in-view');

                if(callback) {
                    callback(entry.target);
                }

            } else {
                if(fadeOut)
                    entry.target.classList.remove('in-view');
            }
        });
    }, observerOptions);

    items.forEach(item => {
        observer.observe(item);
    });
}

function itemTemplate1(item) {
    return `
        <li class="drawer-item drawer-dropdown">
            <label class="checkbox-group">
                <input class="d-none item-checkbox" type="checkbox" value="${item}">
                <span class="checkbox-pseudo">
                <i class="fa fa-check"></i></span>
                ${item}
            </label>
        </li>`;
}
window.itemTemplate1 = itemTemplate1;

function itemTemplate2(item) {
    return `
        <li class="drawer-item drawer-dropdown">
            <label class="checkbox-group justify-content-between">
                <span>${item}</span>
                <input class="d-none item-checkbox" type="checkbox" value="${item}">
                <span class="checkbox-pseudo">
                <i class="fa fa-check"></i></span>
            </label>
        </li>`;
}
window.itemTemplate2 = itemTemplate2;

//trigger drawer and filtering
var filterList = function(inputSelector, drawerSelector, items, template) {
    const inputElement = document.querySelector(inputSelector);
    const drawerElement = document.querySelector(drawerSelector);
    
    function renderList(filter = '') {
        let list = '';
        items.filter(item => item.toLowerCase().includes(filter.toLowerCase())).forEach(item => {
            if(item) 
                list += template(item);
        });
        
        if (list) {
            list = `<ul class="drawer-list reset-list">${list}</ul>`;
            drawerElement.innerHTML = list;
            addCheckboxEventListeners();
        } else {
            drawerElement.innerHTML = '';
        }
    }

    function addCheckboxEventListeners() {
        const checkboxes = drawerElement.querySelectorAll('.item-checkbox');
        checkboxes.forEach(checkbox => {
            checkbox.addEventListener('change', updateInputField);
        });
    }

    function updateInputField() {
        const selectedValues = Array.from(drawerElement.querySelectorAll('.item-checkbox:checked'))
            .map(checkbox => checkbox.value)
            .join(', ');
        
        document.querySelector('.search-group_active')?.classList.remove('search-group_active');
        document.querySelector('.mobile-search-results.open')?.classList.remove('open');
        
        inputElement.value = selectedValues;
    }

    inputElement.addEventListener('input', function() {
        const filter = inputElement.value;

        renderList(filter);
    });

    renderList();
}

const jobTitles = ["Band 5 RMN", "Band 5 - Community Nurse", "Band 7 - ENP", "Band 5 - RGN"];
const locations = ["New York", "Los Angeles", "Chicago", "Houston", "Phoenix"];

window.jobTitles = jobTitles;
window.locations = locations;
window.filterList = filterList;


function resizeCarouselImages() {
    let maxTextHeight = 0;
    const textContainers = document.querySelectorAll('.active-resize .swipe-carousel-text-container');

    // Calculate maximum text height
    textContainers.forEach((el) => {
        const textHeight = el.offsetHeight; // Similar to outerHeight(true)
        if (textHeight > maxTextHeight) {
            maxTextHeight = textHeight;
        }
    });

    const header = document.getElementById('header').offsetHeight;
    const viewportHeight = window.innerHeight;
    let calcImgHeight = viewportHeight - maxTextHeight - header - 100;

    // Adjust image height for smaller screens
    if (window.innerWidth < 768) {
        calcImgHeight += 50;
    }

    const images = document.querySelectorAll('.active-resize .swipe-carousel-image');

    // Set calculated image height
    images.forEach((el) => {
        el.style.height = `${calcImgHeight}px`;
    });
}

function countNumbers(element) {
     
    var countTo = parseInt(element.getAttribute('data-count'), 10);
    var countNum = parseInt(element.textContent, 10);
    
    // Function to handle animation logic
    var animateCount = function(start, end, duration) {
        var startTime = null;

        function step(timestamp) {
            if (!startTime) startTime = timestamp;
            var progress = timestamp - startTime;
            var current = Math.min(Math.floor((progress / duration) * (end - start) + start), end);
            element.textContent = current;
            if (progress < duration) {
                requestAnimationFrame(step);
            } else {
                element.textContent = end;  // Set to final value once complete
            }
        }

        requestAnimationFrame(step);
    };

    // Start the counting animation
    animateCount(countNum, countTo, 1000);
    
}

window.countNumbers = countNumbers;

initializeInViewObserver(null, '.numbers-counter .numbers-large', 0.2, false, function(target){
    countNumbers(target);
});

initializeInViewObserver(null, '.counter', 0.1, false, function(target){
    countNumbers(target);
});

initializeInViewObserver(null, '.circular-progress', 0.15, false, function(target){
    // countNumbers(target);
});


$(document).ready(function () {

    //SEARCH FORM 

    // const shifts = ["Day", "Night", "Mixed"];

    // job titles, locations, and shifts fitering
    // filterList('#job_title', '#job_title_drawer', jobTitles, itemTemplate1);
    // filterList('#location', '#location_drawer', locations, itemTemplate1);
    // hide b2b func
    // filterList('#shifts', '#shifts_drawer', shifts, itemTemplate1);

    // mobile search inputs fitering
    filterList('#ms_job_title', '#ms_job_title_drawer', jobTitles, itemTemplate2);
    filterList('#ms_location', '#ms_location_drawer', locations, itemTemplate2);
    // filterList('#ms_shifts', '#ms_shifts_drawer', shifts, itemTemplate2);

    //mobile result lists
    function showList(inputSelector, listSelector, event = 'input') {
        $(inputSelector).on(event,function(){
            $('.mobile-search-result').removeClass('active');
            $('.mobile-search-results').addClass('open');
            $(listSelector).addClass('active');
        })
    }

    showList('#ms_job_title','#ms_job_title_drawer', 'click');
    showList('#ms_location','#ms_location_drawer', 'click');
    // showList('#ms_shifts','#ms_shifts_drawer', 'click');
    // showList('#ms_availability_picker','#datepicker-container', 'click');

    $('#results-close').on('click', function() {
        $('.mobile-search-results').removeClass('open');
    })

    
    // hamburger & menu 
    // $('#hamburger').click(function() {
    //     $(this).toggleClass('open');
    //     if (!$(this).hasClass('open')) {
    //         console.log('test');
            
    //         $('.drawer-fixed').removeClass('active');
    //         $('body').removeClass('drawer-open');
    //         $('#content').css('right', 0);
    //         e.preventDefault();
    //     }
    // })

    // LOGIN REGISTER DRAWER  
    // function activateDrawer(buttonSelector, drawerSelector) {
    //     let drawer = $(drawerSelector);
    //     let shift = drawer.outerWidth(true);
    //     let margin = 50;
        
    //     if($(window).width() < 1400) {
    //         margin = 28;
    //     } 

    //     if($(window).width() < 768) {
    //         margin = 16;
    //     } 

    //     drawer.css('right', `-${shift+100}px`);
        
    //     $(buttonSelector).on('click', function(e){
    //         e.preventDefault();
    //         drawer.addClass('active');
    //         $('.drawer-fixed').removeClass('active');
    //         $(drawerSelector).addClass('active');
    //         $('body').addClass('drawer-open');
    //         $('#content').css('right', shift-margin+'px');
    //     });
    // }

    // function runDrawer(buttonSelector, drawerSelector) {
    //     let drawer = $(drawerSelector);
    //     let shift = drawer.outerWidth(true);
    //     let margin = 50;
        
    //     if($(window).width() < 1400) {
    //         margin = 28;
    //     } 

    //     if($(window).width() < 768) {
    //         margin = 16;
    //     } 

    //     drawer.css('right', `-${shift+100}px`);
        
    //     drawer.addClass('active');
    //     $('.drawer-fixed').removeClass('active');
    //     $(drawerSelector).addClass('active');
    //     $('body').addClass('drawer-open');
    //     $('#content').css('right', shift-margin+'px');
    
    // }

    // activateDrawer('.btn-login', '#drawer-login');
    // activateDrawer('.btn-reg, .btn-register', '#drawer-register');
    // activateDrawer('#show-mobile-search', '#drawer-search');
    // activateDrawer('#hamburger', '#drawer-menu');

    // $('.drawer-close').on('click', function(e) {
    //     $('.drawer-fixed').removeClass('active');
    //     $('body').removeClass('drawer-open');
    //     $('#content').css('right', 0);

    //     if($('#hamburger').hasClass('open')) {
    //         runDrawer('#hamburger', '#drawer-menu');
    //     }

    //     e.preventDefault();
    // });

    //DESKTOP JOB SEARCH

    // close drawers on click outside
    // $(document).on('click', function (event) {
    //     var $form = $('#search, #fixed, .btn-account');
    //     if (!$form.is(event.target) && $form.has(event.target).length === 0) {
    //         $form.removeClass('expanded').addClass('collapsed');
    //         $('.search-group_active').removeClass('search-group_active');
    //         $form.find('.show').removeClass('show');
    //         $('#header').removeClass('form-expanded');
    //     }
    // });

    
    //AVAILABLITY CALENDAR

    // Special case for days
    function getDayWithSuffix(day) {
        if (day > 3 && day < 21) return day + 'th'; 
        switch (day % 10) {
            case 1: return day + "st";
            case 2: return day + "nd";
            case 3: return day + "rd";
            default: return day + "th";
        }
    }

    const date = new Date();
    const datepickerOptions = {
        multidate: true,
        weekStart: 1,
        startDate: date,
        keepOpen: true,
        todayHighlight: true,  
        format: {
            toDisplay: function (date, format, language) {
                const day = date.getDate();
                const dayWithSuffix = getDayWithSuffix(day);
                const month = date.toLocaleString('default', { month: 'long' });
                const year = date.getFullYear();
                return `${dayWithSuffix} ${month} ${year}`;
            },
            toValue: function (date, format, language) {
                return date;
            }
        }
    };

    function formatDates(e, id){
        const dates = e.dates;
        const formattedDates = dates.map(date => date.toISOString().split('T')[0]);
        document.getElementById(id).value = formattedDates.join(', ');
    }

    // $('#availability_picker').datepicker({...datepickerOptions, container: '#availability_drawer'}).on('changeDate', function(e) {
    //     formatDates(e, 'availability');
    // }).on('changeDate', function(e){
    //     $('.search-group_active').removeClass('search-group_active');
    // });

    // $('#ms_availability_picker').datepicker({...datepickerOptions, container: '#datepicker-container'}).on('changeDate', function(e) {
    //     formatDates(e, 'ms_availability');
    // }).on('changeDate', function(e){
    //     $('.mobile-search-results').removeClass('open');
    // });
});


//prevent effects clicking empty links
document.addEventListener('click', function(event) {
    let target = event.target;
    
    // Traverse up the DOM in case the clicked element is inside an <a> tag
    while (target && target !== document) {
        if (target.tagName === 'A' && target.getAttribute('href') === '#') {
            event.preventDefault(); // Prevent default scroll-to-top behavior
            break; // Stop the loop once we've handled the click on the anchor tag
        }
        target = target.parentNode; // Traverse upwards to find the parent <a> tag, if necessary
    }
});


const portraitQuery = window.matchMedia("(orientation: portrait)");
const landscapeQuery = window.matchMedia("(orientation: landscape)");

window.portraitQuery = portraitQuery;


function setupSlider(moduleId, sliderContainer, tabButtons = null, arrows = null, settings, totalItems = 0) {
    var glide = new Glide(`#${moduleId} ${sliderContainer}`, settings);

    if(tabButtons){
        var buttons = document.querySelectorAll(`#${moduleId} ${tabButtons}`);

        function setActiveButton(index) {
            buttons.forEach(function (button, i) {
                button.classList.toggle('active', i === index);
            });
        }
        
        if(buttons){

            buttons.forEach(function (button, index) {
                button.addEventListener('click', function () {
                    glide.go(`=${index}`);
                    setActiveButton(index);
                });
            });

            glide.on('run.after', function () {
                setActiveButton(glide.index);
            });

        }
    }

    const prevButton = arrows?.prev && document.querySelector(`#${moduleId} ${arrows.prev}`);
    const nextButton = arrows?.next && document.querySelector(`#${moduleId} ${arrows.next}`);
    const moreButton = arrows?.more && document.querySelector(`#${moduleId} ${arrows.more}`);    

    const updateNavigationButtons = () => {

        const totalSlides = glide.settings.perView;
        const currentIndex = glide.index

        if (prevButton) {
            prevButton.classList.toggle('disabled', currentIndex === 0);
        }

        const isLastSlide = currentIndex + totalSlides >= totalItems;

        if (nextButton) {
            nextButton.classList.toggle('disabled', isLastSlide);
        }
        if (moreButton) {
            moreButton.classList.toggle('active', isLastSlide);
        }
    };

    if (prevButton) {
        console.log('prevButton');
        prevButton.addEventListener('click', (event) => {
            event.preventDefault();
            glide.go('<');
        });
    }

    if (nextButton) {
        console.log('nextButton');
        nextButton.addEventListener('click', (event) => {
            event.preventDefault();
            glide.go('>');
        });
    }

    glide.on('swipe.move', (event) => {
        const totalSlides = glide.settings.perView;
        const currentIndex = glide.index;
        const isLastSlide = currentIndex + totalSlides >= totalItems;

        if (isLastSlide && window.innerWidth > 991) {
            glide.go('<<');
        }
    });

    glide.on('run', updateNavigationButtons);
    updateNavigationButtons();

    glide.mount();
}

window.setupSlider = setupSlider;

function observeElementMutations(parentId = 'content', elementId, onElementAdded, onElementRemoved) {

    const parentElement = document.getElementById(parentId);
    if (!parentElement) {
        console.error(`Parent element with id ${parentId} not found.`);
        return;
    }

    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            mutation.addedNodes.forEach((node) => {
                if (node.id === elementId) {
                    onElementAdded(node);
                }
            });

            mutation.removedNodes.forEach((node) => {
                if (node.id === elementId) {
                    onElementRemoved(node);
                }
            });
        });
    });

    observer.observe(parentElement, { childList: true, subtree: true });
}

window.observeElementMutations = observeElementMutations;